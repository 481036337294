import React from "react"

import CountryPage from "../components/country-page"
import withLocation from "../components/with-location"

type Props = {
  searchString: string
}

const UruguayPage = ({ searchString }: Props) => (
  <CountryPage
    country="uruguay"
    date="05/02/2020"
    time="19:00 Hrs."
    flag="uruguay"
    office="World Trade Center Montevideo"
    address="Avenida Doctor Luis Alberto de Herrera 1248"
    searchString={searchString}
  />
)

export default withLocation(UruguayPage)

import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const HeadContainer = styled.div`
  max-width: 688px;
  margin: 0 auto;
  @media (max-width: 420px) {
    margin: 0 1rem;
  }
`

const HeadLine = styled.h2`
  max-width: 680px;
  margin: 3rem auto;
  font-size: 2.4375em;
  text-align: center;
  line-height: 1.2;
`

const Container = styled.div`
  max-width: 640px;
  margin: 0 auto;
`

const ScheduleBox = styled.div`
  border: 1px solid rgb(113, 197, 82);
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  @media (max-width: 420px) {
    flex-direction: column;
    padding: 0;
    margin: 0 1rem;
  }
`

const BoxColumn = styled.div``

const ScheduleTitle = styled.h5`
  max-width: 96px;
  font-size: 1em;
  text-align: center;
  padding: 0px 0.75rem 0.25rem;
  margin: 1em auto;
  border-bottom: 1px solid rgb(113, 197, 82);
`

const ScheduleDescription = styled.p`
  font-size: 0.875em;
  text-align: center;
  padding: 0px 0.75rem 0.25rem;
  color: #e2e2e2;
`

const Country = styled.h4`
  text-align: center;
`

const Flag = styled.img`
  width: 18px;
  height: 12px;
`

const IframeContainer = styled.div`
  max-width: 300px;
  margin: 0 auto;
  padding: 4rem 1rem 1rem;
`

const Iframe = styled.iframe`
  height: 540px;
  margin: 0 auto;
  @media (max-width: 320px) {
    width: 290px;
  }
`

const IframeTitle = styled.h4`
  font-size: 1.125em;
  color: rgb(113, 197, 82);
`

const WhatYouLearn = styled.div`
  max-width: 528px;
  margin: 0 auto;
  padding: 0 0 3rem;
`

const WhatYouLearnTitle = styled.h4`
  text-align: center;
  color: #72c552;
  padding: 0.5rem 0;
`

const TopicList = styled.ul`
  padding: 0;
  list-style-type: none;
  padding-left: 1rem;
  @media (max-width: 420px) {
    padding-left: 0;
  }
`

const TopicItem = styled.li`
  display: flex;
  margin-bottom: 1rem;
`

const Topic = styled.div`
  margin-left: 1rem;
`

const IsotipoWrapper = styled.div``

const Isotipo = styled.img`
  width: 10px;
  height: 19px;
`

type Props = {
  country: string
  date: string
  time: string
  flag: string
  office: string
  address: string
  searchString?: string
}

const CountryPage = ({
  country,
  date,
  time,
  flag,
  office,
  address,
  searchString,
}: Props) => (
  <Layout>
    <SEO title={country.toLocaleUpperCase()} />

    <Country>
      <Flag src={`/${flag}-flag.png`} alt={country} />{" "}
      {country.toLocaleUpperCase()}
    </Country>

    <Hero />

    <HeadContainer>
      <HeadLine>
        Todo lo que tienes que saber sobre el Mercado Financiero 2020
      </HeadLine>

      <WhatYouLearn>
        <WhatYouLearnTitle>EN ESTE EVENTO APRENDERÁS</WhatYouLearnTitle>

        <TopicList>
          <TopicItem>
            <IsotipoWrapper>
              <Isotipo src={`/isotipo.png`} alt="isotipo" />{" "}
            </IsotipoWrapper>
            <Topic>
              <strong>Proyecciones</strong> de Mercado para este 2020
            </Topic>
          </TopicItem>

          <TopicItem>
            <IsotipoWrapper>
              <Isotipo src={`/isotipo.png`} alt="isotipo" />{" "}
            </IsotipoWrapper>
            <Topic>
              <strong>Cómo hacer Trading</strong> en Acciones, Commodities y
              Divisas
            </Topic>
          </TopicItem>

          <TopicItem>
            <IsotipoWrapper>
              <Isotipo src={`/isotipo.png`} alt="isotipo" />{" "}
            </IsotipoWrapper>
            <Topic>
              <strong>Análisis Fundamental y Técnico</strong> de las
              oportunidades más importantes del año
            </Topic>
          </TopicItem>
        </TopicList>
      </WhatYouLearn>
    </HeadContainer>

    <Container>
      <ScheduleBox>
        <BoxColumn>
          <ScheduleTitle>¿Cuándo?</ScheduleTitle>
          <ScheduleDescription>{date}</ScheduleDescription>
        </BoxColumn>

        <BoxColumn>
          <ScheduleTitle>¿Dónde?</ScheduleTitle>
          <ScheduleDescription>
            <strong>{office}</strong>
            <br />
            {address}
          </ScheduleDescription>
        </BoxColumn>

        <BoxColumn>
          <ScheduleTitle>¿A qué hora?</ScheduleTitle>
          <ScheduleDescription>{time}</ScheduleDescription>
        </BoxColumn>
      </ScheduleBox>

      <IframeContainer>
        <IframeTitle>INSCRÍBETE AL EVENTO</IframeTitle>
        <Iframe
          title="RISE"
          className="utm-iframe"
          src={`https://cloud.info.capitaria.com/rise-evento/${searchString}`}
          frameBorder="0"
        ></Iframe>
      </IframeContainer>
    </Container>
  </Layout>
)

export default CountryPage
